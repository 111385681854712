html {
  /* create modern font */
  font-family: 'Roboto', sans-serif;
  height: 100%;
}

body, #root {
  height: 100%;
  margin: 0;
}

