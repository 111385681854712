menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    height: max-content;

    margin: 0;
    z-index: 100;
    font-size: 12px;
}

/*style the items of menu*/
.menu-item {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    background: #000;
}