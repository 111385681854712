.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.card img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    justify-self: center;
    text-align: center;
    margin: 5px auto;

}