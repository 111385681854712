.hero {
    /* center */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-style: italic;

}

html{
    scroll-behavior: smooth;
    height: 100%;
    background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgb(44, 13, 136) 0%, rgb(0, 0, 0) 100%) !important;
}



.layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
}

