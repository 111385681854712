.sidebar {

    border-right: #a8a8a8 1px solid;
    color: black;
    background: #fff7eb;
}

.bottomItem{
    margin-top: auto !important;
}

#logo{
    text-decoration: none;
    color: black;

    margin: 0px 10px 0px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;

    padding: 0px 10px 0px 10px;
    border-bottom: #a8a8a8 1px solid;

    border-bottom: #a8a8a8 1px solid;

    background: -webkit-linear-gradient(180deg, #161679 20%, #1b61a8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-weight: bolder;
    font-size: x-large;


}

#logo:hover{
    text-decoration: none;
    cursor: pointer;
}

#logout{
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
}

#logout:hover{
    cursor: pointer;
    background: rgba(168, 168, 168, 0.27);
}


/* if screen mobile, center */
@media (max-width: 600px) {
    .bottomItem{
        margin-top: 0 !important;
    }

}