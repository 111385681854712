.dashboard {
    display: grid;
    grid-template-columns: 1fr 7fr;
    grid-template-rows: 1fr;
    height: 100%;
    background: #f6f1ea;
}

.sidebar {
    grid-column-start: 1;
    margin-right: 10px;
    padding: 10px;

}

.content {
    grid-column-start: 2;
    margin :10px;
}

.pageHeader{
    font-size: x-large;
    font-weight: bold;

    background: -webkit-linear-gradient(180deg, #1c1c31 10%, #1975d1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* if screen make sidebar smaller */
@media (max-width: 600px) {
    .dashboard {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 7fr;
        height: 100%;
        background: #f6f1ea;
    }

    .sidebar {
        grid-row-start: 1;
        margin-right: 0px;
        padding: 10px;
        grid-column-start: 1;

    }

    .content {
        grid-row-start: 2;
        margin :10px;
        grid-column-start: 1;

    }
}

html{
    background: #F6F1EA !important;
}
